import Spinner from '@/assets/icons/spinner.svg?react';
import styles from './loading-spinner.module.scss';
import { classnames } from '@/utils/functions';
import { FC } from 'react';

interface LoadingSpinnerProps {
  className?: string;
}
const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className = '' }) => {
  return (
    <Spinner className={classnames(styles['loading-spinner'], className)} />
  );
};

export default LoadingSpinner;
