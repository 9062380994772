import InputBox from '../../components/InputBox';
import Conversation from './components/Conversation';
import styles from './thread.module.scss';
import Sidebar from './components/Sidebar';
import Title from './components/Title';
import ThreadOptions from './components/ThreadOptions';
import Disclaimer from '@/components/Disclaimer';
import { SidebarProvider } from './context/SidebarProvider';
import SEOHelmet from '@/components/SEOHelmet';
import useThread from './hooks/useThread';

const Thread = () => {
  const { id, title } = useThread();
  return (
    <div className={styles['thread-wrapper']}>
      <SEOHelmet title={id ? `🧵 ➤ ${title}` : 'New Thread'} index={false} />
      <SidebarProvider>
        <div className={styles['thread-topbar']}>
          <Title />
          <ThreadOptions />
        </div>
        <section className={styles['thread-main']}>
          <Conversation />
          <InputBox />
          <Disclaimer />
        </section>
        <Sidebar />
      </SidebarProvider>
    </div>
  );
};

export default Thread;
