export type ThreadID = string | undefined;
export type ThreadStatus = 'READY' | 'TYPING' | 'CITING' | 'FETCHING' | 'ERROR';
export type ThreadMessageAuthor = 'USER' | 'ASSISTANT';

export interface Thread {
  id: ThreadID;
  title: string;
  blocks: ThreadBlock[];
  filters: ThreadFilters;
}

export interface APIThread {
  thread_id: string;
  title: string;
  thread_items: APIThreadBlock[];
  filters: APIThreadFilters;
}

export interface ThreadResponse {
  response: MessageChunk[];
  sources?: ResponseSource[];
}

export interface ThreadBlock extends ThreadResponse {
  id?: string;
  disliked: boolean;
  prompt: string;
  queryDate: Date;
}

export interface APIThreadBlock {
  message_id: string;
  query_ts: string;
  user_input: string;
  llm_response: string;
  rag_sources: ResponseSource[];
}

export interface Citation {
  source_id: number;
  details: {
    content: string;
    relevance: string;
  }[];
}

export interface MessageChunk {
  text: string;
  citations: Citation[];
}

export interface ThreadFilters {
  countries: string[];
  sources: string[];
  earliestDate?: string;
  latestDate?: string;
}

export interface APIThreadFilters {
  source_category: string[];
  publish_date: { earliest: number; latest: number };
  countries: string[];
}
export interface ResponseSource {
  title: string;
  url: string;
  logo: string;
}

export interface WSParameters {
  user_sub: string;
  user_input: string;
  action: 'createThreadPre' | 'addMessageThreadPre';
  thread_id?: string;
  filters?: {
    source_category: string[];
    publish_date: { earliest: number; latest: number };
    countries: string[];
  };
}

export interface WSThread {
  success?: boolean;
  message?: string;
  stream_id?: string;
  data: {
    thread_id: string;
    user_input: string;
    queryTS?: number;
    connectionId?: string;
    title?: string;
    message_id: string;
    type?:
      | 'streamNewMessage'
      | 'streamProcess'
      | 'streamSources'
      | 'streamComplete'
      | 'streamError';
    additional_data?: {
      buffered_streaming_contents: string;
    };
    message: string;
    process?: string;
    rag_sources?: ResponseSource[];
  };
}

export const DEFAULT_FILTERS: ThreadFilters = {
  countries: [],
  sources: [],
};
