import { FC, ReactNode } from 'react';
import styles from './icon-heading.module.scss';

interface IconHeadingProps {
  children: ReactNode;
}

const IconHeading: FC<IconHeadingProps> = ({ children }) => {
  return <h5 className={styles['icon-heading']}>{children}</h5>;
};

export default IconHeading;
