import { MessageChunk } from '@/models/thread';

export const getToday = (): Date => new Date();

export const getTodayStr = (): string => convertDateToString(getToday());

export const dateDiffInDays = (a: Date, b: Date): number => {
  const dateA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const dateB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((dateB - dateA) / (1000 * 60 * 60 * 24));
};

export const convertDateToString = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

export const getRandomNumber = (min: number, max: number): number => {
  return Math.random() * (max - min) + min; // Random
};

export const convertTimestampToString = (timestamp: number): string => {
  if (Number.isNaN(timestamp) || timestamp === 0) return '';
  return convertDateToString(new Date(timestamp));
};

export const truncateText = (text: string, limit: number): string => {
  if (text === null) return '';
  if (text.length > limit) {
    return text.substring(0, limit) + '...';
  } else {
    return text;
  }
};

export const extractBaseURL = (url: string): string => {
  const matchArray = url.match(/^(https?:\/\/[^/]+)/);

  return matchArray ? matchArray[1] : url;
};

export const classnames = (
  ...args: (string | Record<string, boolean>)[]
): string => {
  const classes = [];

  for (const arg of args) {
    if (!arg) continue;

    const argType = typeof arg;

    if (argType === 'string') {
      classes.push(arg);
    } else if (argType === 'object') {
      const obj = arg as Record<string, boolean>;

      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          classes.push(key);
        }
      });
    }
  }

  return classes.join(' ');
};

export const parseFullMessage = (response: string): MessageChunk[] => {
  const processedString = (response as string).replace(/\\\\n/g, '\n');
  let res: MessageChunk[] = [];

  if (response === null || response === '') return res;
  try {
    const tmp: { answer_segments: MessageChunk[] } =
      JSON.parse(processedString);

    if (!tmp.answer_segments) {
      throw new Error('Invalid JSON structure');
    } else {
      res = tmp.answer_segments;
    }
  } catch (e) {
    res = [{ text: response as string, citations: [] }];
  }

  return res;
};
