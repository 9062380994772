import { ThreadBlock } from '@/models/thread';
import { FC, ReactNode, createContext } from 'react';

interface BlockContextProps {
  block: ThreadBlock;
  isLast: boolean;
}

export const BlockContext = createContext<BlockContextProps>({
  block: {
    id: '',
    prompt: '',
    response: [],
    queryDate: new Date(),
    sources: [],
    disliked: false,
  },
  isLast: false,
});

interface BlockProviderProps {
  block: ThreadBlock;
  isLast: boolean;
  children: ReactNode;
}
export const BlockProvider: FC<BlockProviderProps> = ({
  block,
  isLast,
  children,
}) => {
  return (
    <BlockContext.Provider value={{ block, isLast }}>
      {children}
    </BlockContext.Provider>
  );
};
