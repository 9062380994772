import { FC, ReactNode } from 'react';
import styles from './base-message.module.scss';
import { ThreadMessageAuthor } from '@/models/thread';
import SourcesIcon from '@/assets/icons/database.svg?react';
import { extractBaseURL, truncateText } from '@/utils/functions';
import useSession from '@/hooks/useSession';
import useBlock from '@/pages/Thread/hooks/useBlock';
import IconHeading from '../../IconHeading';
import CitationIndicator from '../../CitationIndicator';
import Options from '../Options';
import useThread from '@/pages/Thread/hooks/useThread';

interface BaseMessageProps {
  author: ThreadMessageAuthor;
  children: ReactNode;
}

const BaseMessage: FC<BaseMessageProps> = ({ author, children }) => {
  const { pushUserEvent } = useSession();
  const { status } = useThread();
  const { block, isLast } = useBlock();
  const { sources } = block;

  return (
    <div
      data-testid={`msg-${author}`}
      className={`${styles.message} ${
        author === 'USER' ? styles['user-message'] : styles['assistant-message']
      }`}
    >
      <div className={styles['message-content']}>{children}</div>
      {author === 'ASSISTANT' && (
        <>
          {(!isLast || status === 'READY' || status === 'CITING') &&
            sources &&
            sources.length > 0 && (
              <div className={styles['message-sources']}>
                <IconHeading>
                  <SourcesIcon /> Sources ({sources.length})
                </IconHeading>
                <ul>
                  {sources?.map(({ url, title }, i) => {
                    const baseURL = extractBaseURL(url);
                    const truncatedTitle = truncateText(title, 60);
                    return (
                      <li key={i}>
                        <div>
                          <CitationIndicator id={i + 1} />
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              pushUserEvent('click_response_source');
                            }}
                          >
                            {truncatedTitle}
                          </a>
                        </div>
                        <span>— {baseURL}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          <Options />
        </>
      )}
    </div>
  );
};

export default BaseMessage;
