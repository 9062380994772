import { MessageChunk } from '@/models/thread';
import { getRandomNumber } from '@/utils/functions';
import { useEffect, useRef, useState } from 'react';

const useTypingEffect = (isTyping: boolean, totalChunks: MessageChunk[]) => {
  const typingInterval = useRef<NodeJS.Timeout | undefined>(undefined);
  const chunkCursor = useRef(0);
  const typingCursor = useRef(0);
  const [chunks, setChunks] = useState<MessageChunk[]>([]);

  useEffect(() => {
    return () => clearInterval(typingInterval.current);
  }, []);

  useEffect(() => {
    if (!isTyping || totalChunks.length === 0) return;

    if (typingInterval.current) {
      clearInterval(typingInterval.current);
    }
    typingInterval.current = setInterval(() => {
      const currentChunkText = totalChunks[chunkCursor.current].text;

      if (currentChunkText === undefined) {
        clearInterval(typingInterval.current);
      } else if (typingCursor.current < currentChunkText.length) {
        let nextCursor =
          typingCursor.current + Math.floor(getRandomNumber(1, 10));
        if (nextCursor > currentChunkText.length) {
          nextCursor = currentChunkText.length;
        }
        typingCursor.current = nextCursor;
        const newText = currentChunkText.substring(0, typingCursor.current);

        setChunks((prev) => {
          prev[chunkCursor.current].text = newText;

          if (
            totalChunks[chunkCursor.current].citations?.length > 0 &&
            prev[chunkCursor.current].citations.length === 0
          ) {
            prev[chunkCursor.current].citations =
              totalChunks[chunkCursor.current].citations;
          }
          return [...prev];
        });
      } else if (
        chunkCursor.current < totalChunks.length - 1 &&
        typingCursor.current !== 0 &&
        typingCursor.current >= currentChunkText.length
      ) {
        typingCursor.current = 0;
        chunkCursor.current++;

        setChunks((prev) => {
          const tmp = [...prev];
          if (tmp[tmp.length - 1].text !== '') {
            tmp.push({
              text: '',
              citations: [],
            });
          }
          return tmp;
        });
      } else {
        clearInterval(typingInterval.current);
      }
    }, 40);

    return () => clearInterval(typingInterval.current);
  }, [isTyping, totalChunks]);

  return { chunks, setChunks };
};

export default useTypingEffect;
