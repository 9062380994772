import { FC } from 'react';
import Button from '../Button';
import styles from './pricing-table.module.scss';
import useSession from '@/hooks/useSession';
import XmarkIcon from '@/assets/icons/xmark.svg?react';
import CheckIcon from '@/assets/icons/check.svg?react';
import EmailLink from '../EmailLink';

enum PlanFeatureType {
  INCLUDED,
  NOT_INCLUDED,
}

interface Plan {
  id: string;
  name: string;
  description: string;
  price?: number;
  features: {
    type: PlanFeatureType;
    description: string;
    helpText?: string;
  }[];
  disclaimer?: string;
  link?: string;
}

const PLANS: Plan[] = [
  {
    id: 'Free',
    name: 'Starter',
    description:
      'Basic access to GANNET with essential features for casual users. For those who want to explore the benefits of using AI to assist in Humanitarian Aid.',
    price: 0,
    features: [
      { type: PlanFeatureType.INCLUDED, description: '3 Months of Data.' },
      { type: PlanFeatureType.INCLUDED, description: '10 Prompts per Day. ' },
      { type: PlanFeatureType.INCLUDED, description: '70 Prompts per Month.' },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Table of Contents enabled on Threads.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Bibliography feature enabled on Threads.',
      },
      { type: PlanFeatureType.INCLUDED, description: 'Basic Search.' },
    ],
    disclaimer: '*Subject to availability during high-demand periods.',
  },
  {
    id: 'Plus',
    name: 'Plus',
    description:
      'Access to extended data and advanced features. For those who need more data and prompts to assist in their research.',
    price: 30,
    features: [
      { type: PlanFeatureType.INCLUDED, description: '1 Year of Data.' },
      { type: PlanFeatureType.INCLUDED, description: '20 Prompts per Day.' },
      {
        type: PlanFeatureType.INCLUDED,
        description: '200 Prompts per Month.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Table of Contents enabled on Threads.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Bibliography feature enabled on Threads.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Explore Data Dashboard.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Advanced Thread Search.',
      },
    ],
    link: import.meta.env.VITE_STRIPE_PLUS_URL,
  },

  {
    id: 'Enterprise',
    name: 'Enterprise',
    description:
      "Customized access to GANNET with tailored features for organizations. For those who want a custom solution to fit their organization's needs.",
    features: [
      {
        type: PlanFeatureType.INCLUDED,
        description: '10 Years of Data.',
      },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'All "Plus" plan features.',
      },
      { type: PlanFeatureType.INCLUDED, description: 'Unlimited Prompts.' },
      { type: PlanFeatureType.INCLUDED, description: 'Discounts per account.' },
      { type: PlanFeatureType.INCLUDED, description: 'Dedicated Support.' },
      {
        type: PlanFeatureType.INCLUDED,
        description: 'Custom and private sources.',
      },
    ],
  },
];

interface PricingTableProps {
  heading?: boolean;
}

const PricingTable: FC<PricingTableProps> = ({ heading = false }) => {
  const {
    session: { email, promptUsage },
    pushUserEvent,
  } = useSession();

  const currentPlan = promptUsage.plan;

  return (
    <div className={styles['pricing-table-wrapper']}>
      {heading && <h2>Choose the right plan for You</h2>}

      <div className={styles['pricing-table']}>
        {PLANS.map(({ id, name, price, description, features, link }) => {
          const isCurrentPlan = currentPlan === id;

          return (
            <div
              key={id}
              className={styles['pricing-plan']}
              data-current={isCurrentPlan}
            >
              <div>
                <h3>{name}</h3>

                {typeof price !== 'undefined' && (
                  <h4>
                    ${price} <span>/ month</span>
                  </h4>
                )}
              </div>
              <p>{description}</p>

              <ul>
                {features.map(({ type, description }) => (
                  <li data-feature-type={type}>
                    {type === PlanFeatureType.INCLUDED && (
                      <CheckIcon className={styles['icon']} />
                    )}
                    {type === PlanFeatureType.NOT_INCLUDED && (
                      <XmarkIcon className={styles['icon']} />
                    )}

                    {description}
                  </li>
                ))}
              </ul>

              {id !== 'Free' && !isCurrentPlan && (
                <Button
                  onClick={() => {
                    pushUserEvent(
                      `${
                        link ? 'click_upgrade' : 'click_request_upgrade'
                      }_${id}`
                    );
                    if (link) {
                      window.open(
                        `${
                          import.meta.env.VITE_STRIPE_PLUS_URL
                        }?prefilled_email=${email}`,
                        '_blank'
                      );
                    } else {
                      window.location.href = `mailto:info@gannet.ai?subject=Request Upgrade to ${name}`; // Simulate mailto click
                    }
                  }}
                >
                  {link ? `Get ${name}` : 'Request Upgrade'}
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <p className={styles['pricing-contact']}>
        If you have questions don't hesitate to contact us at <EmailLink />.
      </p>
    </div>
  );
};

export default PricingTable;
