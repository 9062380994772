import { FC } from 'react';

interface HTMLContent {
  text: string;
}

const HTMLContent: FC<HTMLContent> = ({ text = '' }) => {
  return text.split('\n').map((part, i) => (
    <span key={i}>
      {i > 0 && (
        <>
          {part.trim().substring(0, 2) === '- ' ? <br /> : null}
          <br />
        </>
      )}
      {part}
    </span>
  ));
};

export default HTMLContent;
