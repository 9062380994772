import { useNavigate } from 'react-router-dom';
import styles from './user-options.module.scss';
import useSession from '@/hooks/useSession';
import useLogout from '@/hooks/useLogout';
import DoorOpenIcon from '@/assets/icons/door-open-solid.svg?react';
import RocketIcon from '@/assets/icons/rocket.svg?react';

import { createPortal } from 'react-dom';
import Modal from '@/components/Modal/modal';
import { useState } from 'react';
import { hasThreadsAccess } from '@/utils/session';
import Button from '@/components/Button';
import PricingTable from '@/components/PricingTable';

const UserOptions = ({ collapsed = false }) => {
  const {
    session: { promptUsage, email, roles },
    pushUserEvent,
  } = useSession();

  const [modalContent, setModalContent] = useState<
    'details' | 'pricing' | null
  >(null);
  const logout = useLogout();
  const navigate = useNavigate();

  const getModalTitle = () => {
    if (modalContent === 'details') {
      return 'Limits';
    } else if (modalContent === 'pricing') {
      return 'Pricing Plans';
    } else {
      return '';
    }
  };

  const handleLogOut = async () => {
    await logout();

    navigate('/');
    window.location.reload();
  };
  return (
    <div className={styles['user-options']}>
      {hasThreadsAccess(roles) && (
        <>
          {!collapsed && (
            <div className={styles['user-summary']}>
              <strong>
                Plan
                <button
                  className={styles['user-plan']}
                  onClick={() => {
                    setModalContent('details');
                    pushUserEvent('click_limit_info');
                  }}
                >
                  {promptUsage.plan}
                </button>
              </strong>
              <p>
                <i>Today</i> <span>{promptUsage.usedToday} </span> /{' '}
                <span>{promptUsage.perDay}</span>
              </p>
              <p>
                <i>Month</i>
                <span>{promptUsage.usedThisMonth} </span> /{' '}
                <span>{promptUsage.perMonth}</span>
              </p>
            </div>
          )}

          <Button
            className={styles['upgrade-cta-btn']}
            variation="tertiary"
            onClick={() => {
              setModalContent('pricing');
              pushUserEvent('show_pricing');
            }}
          >
            <RocketIcon className={styles['rocket-icon']} />
            {collapsed ? '' : 'Upgrade Plan'}
          </Button>

          {!collapsed && (
            <div className={styles['user-details']}>
              <p className={styles['user-email']}>
                {email.split('@').map((text, index) => (
                  <span className={styles['user-email-part']} key={index}>
                    {index === 1 && '@'}
                    {text}
                  </span>
                ))}
              </p>
            </div>
          )}
        </>
      )}

      <button className={styles['logout']} onClick={handleLogOut}>
        <DoorOpenIcon />
        {!collapsed && 'Log out'}
      </button>

      {createPortal(
        <Modal
          title={getModalTitle()}
          isOpen={modalContent !== null}
          onClose={() => {
            setModalContent(null);
          }}
          size="large"
        >
          {modalContent === 'details' && (
            <>
              <p>Here's a breakdown of your current plan's limits and usage:</p>

              <ul className={styles['modal-list']}>
                <li>
                  Today,
                  {promptUsage.remainingToday === 0 ? (
                    <>
                      {' '}
                      you've used all your{' '}
                      <strong>{promptUsage.perDay} prompts</strong>.
                    </>
                  ) : (
                    <>
                      you still have{' '}
                      <strong>
                        {promptUsage.remainingToday} prompt
                        {promptUsage.remainingToday === 1 ? '' : 's'}
                      </strong>{' '}
                      remaining out of <span>{promptUsage.perDay}</span>.
                    </>
                  )}
                </li>
                <li>
                  {promptUsage.remainingThisMonth === 0 ? (
                    `
                You've used all your {promptUsage.perMonth} prompts for the month. Please wait until the next month to get more prompts.
              `
                  ) : (
                    <>
                      For the month, you still have{' '}
                      <strong>
                        {promptUsage.remainingThisMonth} prompt
                        {promptUsage.remainingThisMonth === 1 ? '' : 's'} left.
                      </strong>{' '}
                      remaining out of <span>{promptUsage.perMonth}</span>.
                    </>
                  )}
                </li>
              </ul>
              <p>
                Remember, these counts reset at the beginning of each day (GMT
                timezone) and at the start of every month.
              </p>
            </>
          )}
          <PricingTable heading={modalContent === 'details'} />
        </Modal>,
        document.body
      )}
    </div>
  );
};

export default UserOptions;
